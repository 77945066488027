import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import DatePeriodMenu from '@shared/ui/menus/DatePeriodMenu';
import { TopAssetsChart } from '@entities/wallet';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMedium } from '@components/styled/Typography';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import SkeletonGroup from '@shared/ui/progress-loaders/SkeletonGroup';
import TopAssetsTable from './TopAssetsTable';
const TopAssetsContainer = styled(ContainerColumn)((props) => ({
    minHeight: 378,
    boxSizing: 'border-box',
    boxShadow: `0px 1px 3px 0px ${props.theme.customColors.surface.outline}`,
    backgroundColor: props.theme.customColors.surface.surface,
    borderRadius: 12,
    padding: props.theme.spacing_sizes.l,
    gap: 20,
}));
const TitleContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ContentContainer = styled(ContainerRow)(() => ({
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '35px 0px 35px 54px',
    gap: 100,
}));
const TopAssetsTitle = styled(BodyMedium)(props => ({
    padding: `${props.theme.spacing_sizes.xs * 1.25}px 0px`,
    fontWeight: 500,
}));
const Periods = [
    {
        count: 3,
        type: 'month',
        notation: 'short',
    },
    {
        count: 6,
        type: 'month',
        notation: 'short',
    },
    {
        count: 9,
        type: 'month',
        notation: 'short',
    },
    {
        count: 1,
        type: 'year',
        notation: 'long',
    },
];
const MaxTradingPairsCount = 5;
const toFixed2 = (n) => Math.round((n + Number.EPSILON) * 100) / 100;
const EMPTY_DATA = [
    {
        name: 'empty',
        percentage: 100,
        pnl: 0,
        tradesCount: 0,
    },
];
const TopAssets = ({ data, isLoading = false, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = useMemo(() => [
        theme.customColors.graphs.pie_chart.pie_color.dark_blue,
        theme.customColors.graphs.pie_chart.pie_color.light_blue,
        theme.customColors.graphs.pie_chart.pie_color.dark_green,
        theme.customColors.graphs.pie_chart.pie_color.light_green,
        theme.customColors.graphs.pie_chart.pie_color.yellow,
        theme.customColors.graphs.pie_chart.pie_color.orange,
    ], [theme]);
    const tradingPairs = useMemo(() => {
        const totalTradesCount = data.reduce((prev, curr) => prev + curr.tradesCount, 0);
        if (totalTradesCount <= 0) {
            return [];
        }
        const result = [];
        const tradingPairsCount = data.length < MaxTradingPairsCount ?
            data.length :
            MaxTradingPairsCount;
        for (let i = 0; i < tradingPairsCount; i += 1) {
            const tradingPair = data[i];
            result.push({
                name: tradingPair.tradingPairName,
                percentage: toFixed2((tradingPair.tradesCount * 100) / totalTradesCount),
                pnl: tradingPair.pnl,
                tradesCount: tradingPair.tradesCount,
            });
        }
        const other = {
            name: t('placeholders.other', { ns: 'common' }),
            percentage: 0,
            pnl: 0,
            tradesCount: 0,
        };
        for (let i = tradingPairsCount - 1; i < data.length; i += 1) {
            const tradingPair = data[i];
            other.percentage += toFixed2((tradingPair.tradesCount * 100) / totalTradesCount);
            other.pnl += tradingPair.pnl;
            other.tradesCount += tradingPair.tradesCount;
        }
        if (other.tradesCount > 0) {
            result.push(other);
        }
        return result;
    }, [t]);
    const totalTradesDetails = useMemo(() => ({
        pairsCount: data.length,
        tradesCount: data.reduce((tradesCount, currentPair) => tradesCount + currentPair.tradesCount, 0),
        totalPnl: data.reduce((totalPnl, currentPair) => totalPnl + currentPair.pnl, 0),
    }), []);
    const tradingPairsColorsMap = useMemo(() => {
        const colorsMap = {};
        tradingPairs.forEach((p, index) => {
            colorsMap[p.name] = colors[index];
        });
        return colorsMap;
    }, [tradingPairs, colors]);
    const [activeTradingPair, setActiveTradingPair] = useState();
    const onGraphArcHover = (tradingPair, isHovered) => {
        // we dont need hover effect on empty data
        if (isHovered && tradingPairs.length) {
            setActiveTradingPair(tradingPair);
        }
        else {
            setActiveTradingPair(undefined);
        }
    };
    const onTableRowHover = (index, tradingPair, isHovered) => {
        if (isHovered) {
            setActiveTradingPair(tradingPair);
        }
        else {
            setActiveTradingPair(undefined);
        }
    };
    const periods = Periods;
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const onPeriodChange = (p) => {
        setCurrentPeriod(p);
    };
    return (_jsx(TopAssetsContainer, { children: _jsxs(SkeletonGroup, { isLoading: isLoading, children: [_jsxs(TitleContainer, { children: [_jsx(WithSkeleton, { width: 85, height: 24, isLoading: isLoading, children: _jsx(TopAssetsTitle, { children: t('portfolio.top_assets') }) }), tradingPairs.length > 0 && (_jsx(DatePeriodMenu, { periods: Object.values(periods), currentPeriod: currentPeriod, onPeriodChange: onPeriodChange }))] }), _jsxs(ContentContainer, { children: [_jsx(TopAssetsChart, { width: 200, height: 200, data: tradingPairs.length ? tradingPairs : EMPTY_DATA, colors: tradingPairs.length ? tradingPairsColorsMap : { empty: '#EFF1F3' }, activeTradingPair: activeTradingPair, totalTradesDetails: tradingPairs.length ?
                                totalTradesDetails : {
                                pairsCount: 0,
                                totalPnl: 0,
                                tradesCount: 0,
                            }, isEmpty: tradingPairs.length === 0, onHover: onGraphArcHover, isLoading: isLoading }), _jsx(TopAssetsTable, { data: tradingPairs, colors: tradingPairsColorsMap, onRowHover: onTableRowHover, isLoading: isLoading })] })] }) }));
};
export default TopAssets;
